import axios from "axios";

import i18n from "../config/locale/i18n";
import {
  API_BASE_PATH,
  CUSTOMER_ACCOUNT_LOGO,
  ORG_USER_ROLE_API_URL,
  USER_REFRESH_TOKEN_URL,
  USER_SIGN_IN,
} from "../config/service";
import analytics from "../libs/Analytics/analytics";
import { getBasicAnalyticsPayload } from "../libs/Analytics/analytics-payload";
import { ERROR_EVENT } from "../libs/Analytics/global-event-types";
import store from "../reducers/store";
import { DOMAIN_NAMES } from "../utils/app-constants.json";
import { postNotification } from "../utils/notification-message";
import { clearCache, getLoggedInUserAccessToken } from "./account";
import { getAxiosInstance } from "./apiServiceHelper";

export const WEB_REQUEST_TYPE = {
  POST: "POST",
  GET: "GET",
  DELETE: "DELETE",
  PUT: "PUT",
  PATCH: "PATCH",
};

const { dispatch } = store;

const BLACKLISTED_URLS_FOR_ACCESS_DENIED_ERROR = [
  ORG_USER_ROLE_API_URL
];
/**
 * Method for handling all api call to the server
 * @param method
 * @param url
 * @param params
 */

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Do something with response error
    try {
      const analyticsPayload = getBasicAnalyticsPayload();
      const event = {
        eventName: ERROR_EVENT.API,
        additionalProps: {
          ...analyticsPayload,
          apiErrorMetrics: {
            url: error.url,
            status: error.request?.status,
            errorResponse: error.request?.responseText || error.message,
            method: error.method,
            axiosErrorCode: error.code,
            ...error,
          },
        },
      };
      analytics.track(event);
    } catch (e) {
      console.error(e);
    }

    if (error.response) {
      /* The user is not able to see the error message while login, So the LoginUrl is removed from reload */
      const isLoginUrl = error.response?.config?.url === USER_SIGN_IN;
      const isRefreshUrl =
        error.response?.config?.url === USER_REFRESH_TOKEN_URL; //TODO: Add this with proper fix.Removing this temporarily

      if (
        (error.response.status === 401 && !isLoginUrl) ||
        (error.response.status === 400 && isRefreshUrl)
      ) {
        clearCache();
        window.location.reload();
      }

      const isBlacklistedUrl = BLACKLISTED_URLS_FOR_ACCESS_DENIED_ERROR.some(
        (blacklistedUrl) => {
          const regex = new RegExp(blacklistedUrl.replace(/{.*?}/g, ".*"));
          return regex.test(error.response.config.url);
        }
      );

      if (error.response.status === 403 && !isBlacklistedUrl) {
        dispatch(postNotification(error.response.data.ErrorMessage, "danger"));
      }
      return Promise.reject(error.response);
    } else if (error.request) {
      return Promise.reject({
        data: { ErrorMessage: i18n.t("common:errorMessage") },
      });
    } else {
      return Promise.reject({
        data: { ErrorMessage: i18n.t("common:errorMessage") },
      });
    }
  }
);
const controller = new AbortController();

export const getApiService = (
  method,
  url,
  params = {},
  cancelSignal = controller.signal
) => {
  const urlPaths = url.split("/");
  let urlDomain = url.startsWith("/") ? urlPaths[1] : urlPaths[0];

  const domainWithFilters = urlDomain.split("?"); // For cases like /notifications?filter=org
  // Expected values for urlDomain to be org/auth or any other values from DOMAIN_NAMES constant
  urlDomain = domainWithFilters.length > 1 ? domainWithFilters[0] : urlDomain;

  const isWithCredentialsEnabled = DOMAIN_NAMES.some(
    (domain) => urlDomain === domain
  );

  return getAxiosInstance({
    method,
    url,
    params,
    isWithCredentialsEnabled,
    cancelSignal,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw JSON.stringify(error);
    });
};

export const getS3Service = (method, url, params = {}) => {
  delete axios.defaults.headers.common["Authorization"];
  return axios({
    method: method,
    url: url,
    headers: undefined,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getDiagramUrl = (url) => {
  const diagramUrl =
    API_BASE_PATH + url + "?AppToken=" + getLoggedInUserAccessToken();
  return diagramUrl;
};

export const getHeaderLogo = () => {
  return (
    API_BASE_PATH +
    CUSTOMER_ACCOUNT_LOGO +
    "?AppToken=" +
    getLoggedInUserAccessToken()
  );
};

export const uploadFilesToS3 = (
  method,
  url,
  formData,
  fileType,
  options = {},
  headers = {}
) => {
  delete axios.defaults.headers.common["Authorization"];
  return axios({
    url: url,
    method: method,
    data: formData,
    headers: {
      "Content-Type": fileType,
      ...headers,
    },
    ...options,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
